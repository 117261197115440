import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { Mutex } from 'async-mutex'
import {loggedOut, setTokens} from "../Authentication/authSlice";

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
    baseUrl: `https://api.messana.tre.digital/`,
    prepareHeaders: (headers, {getState, endpoint}) => {
        // this method should retrieve the token without a hook
        const token = getState().auth.tokens;
        if (token && !headers.has("Authorization")) {
            headers.set("authorization", `Bearer ${token.accessToken}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()
            try {
                const refreshResult = await baseQuery(
                    {
                        url:'/auth/local/refresh',
                        method:'POST',
                        headers:{
                            Authorization: `Bearer ${api.getState().auth.tokens ? api.getState().auth.tokens.refreshToken : JSON.parse(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME))}`,
                        }},
                    api,
                    extraOptions
                )
                if (refreshResult.data) {
                    api.dispatch(setTokens({tokens:refreshResult.data}))
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(loggedOut())
                }
            }catch (e) {
                api.dispatch(loggedOut())
            } finally {
                // release must be called once the mutex should be released again.
                release()
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }
    return result
}

export const messanaApi = createApi({
    reducerPath: 'messanaApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Dashboard'],
    endpoints: (build) => ({
        getDashboard: build.query({
            query: () => ({
                url: `/dashboard`,
                method: 'GET',
            }),
            providesTags: ['Dashboard']
        }),
        getAllDischarges: build.query({
            query: ({page,pageSize,orderBy,sortOrder, id}) => ({
                url: `credit/all/${id}/?page=${page}&pageSize=${pageSize}`,
                method: 'GET',
            }),
            providesTags: (result,error,arg) => result
                    ? [...result.map(({id}) => ({type: 'Discharge', id})),'Discharge',{type:'Discharges',id:arg.id}, 'Discharges']
                    : ['Discharge','Discharges']
        }),
        addDischarge: build.mutation({
            query: ({body}) => ({
                url: `credit/addCredit`,
                method: 'POST',
                body:body,
            }),
            invalidatesTags: (result, error, arg) =>
                [{type:'creditSummary', id:arg.body.ptrId},{type:'Discharges', id:arg.body.ptrId},'Dashboard'],
        }),
        updateDischarge: build.mutation({
            query: ({body, id, ptrId}) => ({
                url: `credit/${id}`,
                method: 'PATCH',
                body:body,
            }),
            invalidatesTags: (result, error, arg) =>
                [{type:'Discharge', id:arg.id}, {type:'creditSummary', id:arg.ptrId},'Dashboard'],
        }),
        creditSummary: build.query({
            query: ({id}) => ({
                url: `credit/summary/${id}`,
                method: 'GET',
            }),
            providesTags: (result,error,arg) =>
                [{type:'creditSummary', id:arg.id},'creditSummary'],
        }),
        getAllOrders: build.query({
            query: ({page,pageSize,orderBy,sortOrder}) => ({
                url: `order/?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&sortOrder=${sortOrder}`,
                method: 'GET',
            }),
            providesTags: (result,error,arg) =>
                result
                    ? [...result.orders.map(({id})=> ({type: 'Orders', id:id})),'Orders']
                    : ['Orders']
        }),
        getOneOrder: build.query({
            query: ({id}) => ({
                url: `order/${id}`,
                method: 'GET',
            }),
            providesTags: (result,error,arg) =>
                [{type:'Order', id:arg.id},'Order']
        }),
        getStatusTypes: build.query({
            query: () => ({
                url: `order/statusTypes/`,
                method: 'GET',
            })
        }),
        getAllProducts: build.query({
            query: ({filter}) => ({
                url: `order/products/?filter=${filter}`,
                method: 'GET',
            }),
            providesTags: ['Products']
        }),
        updateProduct: build.mutation({
            query: ({id, body}) => ({
                url: `order/product/${id}`,
                method: 'PATCH',
                body:body,
            }),
            invalidatesTags: ['Products']
        }),
        updateOrder: build.mutation({
            query: ({id, body}) => ({
                url: `order/${id}`,
                method: 'PATCH',
                body:body,
            }),
            invalidatesTags: (result, error, arg) =>
                [{type:'Orders', id:arg.id},{type:'Order', id:arg.id},'Products','Dashboard']
        }),
        createOrder: build.mutation({
            query: ({body}) => ({
                url: `order/newOrder`,
                method: 'POST',
                body:body,
            }),
            invalidatesTags: ['Orders','Products','Dashboard']
        }),
        deleteOrder: build.mutation({
            query:({id}) => ({
                url: `order/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Orders','Dashboard']
        }),
        getAllUsers: build.query({
            query: ({page,pageSize,orderBy,sortOrder,body}) => ({
                url: `auth/?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&sortOrder=${sortOrder}`,
                method: 'POST',
                body:body
            }),
            providesTags: (result,error,arg) =>
                result
                    ? [...result.users.map(({id}) => ({type:'Users',id})),'Users']
                    : ['Users']
        }),
        getAllDisabledUsers: build.query({
            query: ({page,pageSize,orderBy,sortOrder}) => ({
                url: `auth/disabledUsers?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&sortOrder=${sortOrder}`,
                method: 'GET',
            }),
            providesTags: (result,error,arg) =>
                result
                    ? [...result.users.map(({id}) => ({type:'Users',id})),'Users']
                    : ['Users']
        }),
        getUser: build.mutation({
            query:()=>({
                url: 'auth/findByToken',
                method: 'GET',
            }),
            providesTags: ['LoggedUser'],
        }),
        login: build.mutation({
            query: ({...body}) => ({
                url: 'auth/signin',
                method: 'POST',
                body: body,
            }),
        }),
        refreshToken: build.mutation({
            query: () => ({
                url:'auth/local/refresh',
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME))}`,
                }
            })
        }),
        logout: build.mutation({

            query: () => ({
                url:'auth/logout',
                method: 'POST',
            })
        }),
        signup: build.mutation({
            query: ({...body}) => ({
                url: 'auth/signup',
                method: 'POST',
                body:body
            })
        }),
        verifyEmailCode: build.mutation({
            query: ({body})=>({
                url:'auth/verifyEmailCode',
                method: 'POST',
                body:body
            }),
            invalidatesTags: (result,error,arg) =>
                [{type:'EmailVerify', email:arg.body.email}],
        }),
        enablePtr: build.mutation({
            query: ({body})=>({
                url:'auth/enablePtr',
                body:body,
                method: 'PATCH',
            }),
            invalidatesTags: (result,error,arg) =>
                ['Dashboard','Users',{type:'User',id:arg.body.id}]
        }),
        updateUser: build.mutation({
            query: ({body})=>({
                url:'auth/updateUser',
                method: 'PATCH',
                body:body,
            }),
            invalidatesTags:(result,error,arg) =>
                [{type:'Users',id:arg.body.id},{type:'User',id:arg.body.id},'LoggedUser'],
        }),
        singleUser: build.query({
            query: ({body})=>({
                url:'auth/singleUser',
                method: 'POST',
                body:body,
            }),
            providesTags: (result,error,arg) =>
                [{type:'User',id:arg.body.id},'User'],
        }),
        changePassword: build.mutation({
            query: ({body})=>({
                url:'auth/changePassword',
                method: 'PATCH',
                body:body,
            })
        }),
        disablePtr: build.mutation({
            query: ({body})=>({
                url:'auth/disablePtr',
                method: 'PATCH',
                body:body,
            }),
            invalidatesTags:(result,error,arg) =>
                ['Dashboard','Users',{type:'User',id:arg.body.id}],
        }),
        addClient: build.mutation({
            query: ({body})=>({
                url:'auth/addClient',
                method: 'POST',
                body:body,
            }),
            invalidatesTags: ['Dashboard','Users']
        }),
        checkToVerifyEmail: build.query({
            query: ({email})=>({
                url:`auth/checkToVerifyEmail?email=${email}`,
                method: 'GET',
            }),
            providesTags:(result,error,arg) =>
                [{type:'EmailVerify', email:arg.email},'EmailVerify']
        }),
        getNotifications: build.mutation({
            query: ()=>({
                url:'email/notifications/unread',
                method: 'GET',
            }),
            providesTags:['Notification'],
        }),
        setNotifications: build.mutation({
            query: ({body})=>({
                url:'email/notifications',
                method: 'PATCH',
                body:body
            }),
            invalidatesTags:['Notification','Notifications']
        }),
        getAllNotifications: build.query({
            query: ({page})=>({
                url:'email/notifications?page='+page,
                method: 'GET',
            }),
            providesTags: ['Notifications'],
        }),
        getResetLink: build.mutation({
            query: ({email})=>({
                url:'auth/requestResetLink',
                method: 'POST',
                body: {email:email},
            })
        }),
        validateToken: build.mutation({
            query: ({email, token})=>({
                url:`auth/validateToken?token=${token}&email=${email}`,
                method: 'GET',
            })
        }),
        resetPassword: build.mutation({
            query: ({body}) => ({
                url: 'auth/resetPassword',
                method: 'POST',
                body:body,
            })
        }),
        privacyAcceptance: build.mutation({
            query: ({body}) => ({
                url: 'auth/privacyAcceptance',
                method: 'PATCH',
                body:body,
            })
        }),
        resendEmailUsers: build.query({
            query: ({page,pageSize,orderBy,sortOrder}) => ({
                url: `auth/resendEmailUsers?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&sortOrder=${sortOrder}`,
                method: 'GET',
            })
        }),
        resendEmail: build.mutation({
            query: ({id}) => ({
                url: 'auth/resendEmail/'+id,
                method: 'POST',
            })
        }),
        agreementAcceptance: build.mutation({
            query: ({body})=>({
                url:'auth/agreementAcceptance',
                method: 'POST',
                body:body
            }),
            invalidatesTags: (result,error,arg) =>
                [{type:'EmailVerify', email:arg.body.email}],
        }),
        getMunicipality: build.query({
            query: ()=>({
                url:'auth/municipality',
                method: 'GET',
            }),
            providesTags: ['Municipality'],
        })
    })
})

export const {
    useGetDashboardQuery,
    useGetAllDischargesQuery,
    useAddDischargeMutation,
    useUpdateDischargeMutation,
    useCreditSummaryQuery,
    useGetAllOrdersQuery,
    useGetOneOrderQuery,
    useGetStatusTypesQuery,
    useGetAllProductsQuery,
    useUpdateOrderMutation,
    useCreateOrderMutation,
    useDeleteOrderMutation,
    useLoginMutation,
    useGetUserMutation,
    useGetAllUsersQuery,
    useGetAllDisabledUsersQuery,
    useRefreshTokenMutation,
    useLogoutMutation,
    useSignupMutation,
    useVerifyEmailCodeMutation,
    useEnablePtrMutation,
    useUpdateUserMutation,
    useSingleUserQuery,
    useChangePasswordMutation,
    useDisablePtrMutation,
    useAddClientMutation,
    useCheckToVerifyEmailQuery,
    useGetNotificationsMutation,
    useSetNotificationsMutation,
    useGetAllNotificationsQuery,
    useGetResetLinkMutation,
    useValidateTokenMutation,
    useResetPasswordMutation,
    usePrivacyAcceptanceMutation,
    useResendEmailUsersQuery,
    useResendEmailMutation,
    useAgreementAcceptanceMutation,
    useGetMunicipalityQuery,
    useUpdateProductMutation
} = messanaApi